import React from 'react'

import Box from 'app/components/base/Box'
import Text from 'app/components/base/Text'
import { palette } from 'app/theme/common/palette'

const ComingSoonBlock = () => {
  return (
    <Box
      sx={{
        height: 32,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: `linear-gradient(90deg, ${palette.secondary.light} 0%, ${palette.secondary.main} 100%)`
      }}
    >
      <Text
        variant="subtitle2"
        color="text.primary"
        sx={{
          textTransform: 'uppercase',
          fontWeight: 600,
          fontFamily: 'Nunito'
        }}
      >
        Coming soon
      </Text>
    </Box>
  )
}

export default ComingSoonBlock
