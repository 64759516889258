import { useRouter } from 'next/router'
import React, { useCallback } from 'react'

import { Stack, StackProps, Link } from '@mui/material'

import Text from 'app/components/base/Text/Text.web'
import { menuItems as siteMenuItems } from 'app/utils/constants/menu.constants'

import {
  LANDING_PATH,
  menuItems
} from '~/views/LandingView/helpers/landing.constants'

type MenuItemProps = {
  text: string
  anchor: string
  onClick?: (anchor: string) => void
}

const MenuItem = ({ text, anchor, onClick = () => {} }: MenuItemProps) => {
  const router = useRouter()
  const isLanding = router?.pathname === LANDING_PATH
  const contactUsLink = anchor === siteMenuItems.contactUs.link
  const href =
    !isLanding && !contactUsLink ? `${LANDING_PATH}/${anchor}` : anchor

  return (
    <Link
      href={href}
      onClick={(e) => {
        if (isLanding && !contactUsLink) {
          e.preventDefault()

          onClick(anchor)
        }
      }}
    >
      <Text
        size={{ xs: 'h2', md: 'body1' }}
        sx={{
          fontSize: { xs: 30, md: 16, lg: 18 },
          fontWeight: { xs: 400, md: 300 },
          color: 'background.default',
          '&:hover': { color: 'secondary.dark' }
        }}
      >
        {text}
      </Text>
    </Link>
  )
}

interface MenuProps extends StackProps {
  closeMenu?: () => void
}

const Menu = ({ closeMenu, ...props }: MenuProps) => {
  const scrollToBlock = useCallback((anchor: string) => {
    setTimeout(() => {
      document.querySelector(`${anchor}`)?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'start'
      })
    }, 0)

    if (closeMenu) {
      closeMenu()
    }
  }, [])

  return (
    <Stack direction="row" spacing={{ xs: 3, md: 2, lg: 3 }} {...props}>
      {menuItems.map((item) => (
        <MenuItem
          key={item.anchor}
          text={item.text}
          anchor={item.anchor}
          onClick={scrollToBlock}
        />
      ))}
    </Stack>
  )
}

export default Menu
