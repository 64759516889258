import dynamic from 'next/dynamic'
import React from 'react'

import { Link } from 'solito/link'

import { Box } from '@mui/material'

import Logo from 'app/assets/images/landing/logo-header.svg'
import MobileLogo from 'app/assets/images/light-logo-header.svg'

import { LANDING_PATH } from '~/views/LandingView/helpers/landing.constants'

const LandingLogo = () => {
  return (
    <Link href={LANDING_PATH}>
      <>
        <Box
          sx={{
            display: { xs: 'none', md: 'flex' },
            alignItems: 'center'
          }}
        >
          <Logo />
        </Box>

        <Box
          sx={{
            display: { xs: 'flex', md: 'none' },
            alignItems: 'center'
          }}
        >
          <MobileLogo />
        </Box>
      </>
    </Link>
  )
}

export default LandingLogo
