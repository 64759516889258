import { useRouter } from 'next/router'
import React from 'react'

import { AppBar, Grid, Stack, Toolbar } from '@mui/material'

import MenuIcon from 'app/assets/icons/header/menu.svg'
import IconButton from 'app/components/base/IconButton'

import SignUpButton from '~/views/LandingView/components/SignUpButton'
import { LANDING_PATH } from '~/views/LandingView/helpers/landing.constants'

import Menu from '../Menu'

import ComingSoonBlock from './components/ComingSoonBlock'
import LandingLogo from './components/LandingLogo'

const Header = ({ onClickMenu }: { onClickMenu: () => void }) => {
  const { pathname } = useRouter()
  const isLanding = pathname === LANDING_PATH

  return (
    <Stack direction="column">
      <AppBar
        position={isLanding ? 'absolute' : 'static'}
        color={isLanding ? 'transparent' : 'primary'}
        elevation={0}
      >
        <ComingSoonBlock />
        <Toolbar
          variant="dense"
          sx={{
            px: { xs: 2, lg: 10, xxl: 25 },
            minHeight: { md: 70 }
          }}
        >
          <Grid
            container
            alignItems="center"
            sx={{ py: { xs: 2, md: isLanding ? 4 : 2.75 } }}
          >
            <Grid item xs={6} sm={7} md={4} xl={3}>
              <LandingLogo />
            </Grid>

            <Grid item xs={6} sm={5} md={8} xl={9}>
              <Stack
                direction="row"
                spacing={{ md: 1, lg: 4 }}
                sx={{
                  justifyContent: 'flex-end',
                  alignItems: 'center'
                }}
              >
                <Menu sx={{ display: { xs: 'none', md: 'flex' } }} />

                <SignUpButton
                  textProps={{ sx: { fontSize: { xs: 14, md: 16 } } }}
                  buttonProps={{
                    sx: {
                      width: { xs: 115, md: 125 },
                      minHeight: { xs: 30, md: 36 },
                      height: { xs: 30, md: 36 }
                    }
                  }}
                />

                <IconButton
                  onClick={onClickMenu}
                  sx={{ display: { xs: 'flex', md: 'none' } }}
                >
                  <MenuIcon />
                </IconButton>
              </Stack>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </Stack>
  )
}

export default Header
