import dynamic from 'next/dynamic'
import React, { Dispatch, SetStateAction, useCallback, useState } from 'react'

import LazyHydrate from 'react-lazy-hydration'

import { Box, Theme, useMediaQuery } from '@mui/material'

import Header from './Header'

const Footer = dynamic(() => import('./Footer'))
const Sidebar = dynamic(() => import('./Sidebar'), { ssr: false })

interface LandingLayoutProps {
  children: React.ReactNode
}

const LandingLayout: RFC<LandingLayoutProps> = ({ children }) => {
  const [isOpenSidebar, setSidebar] = useState<boolean>(false)

  return (
    <LandingLayoutView isOpenSidebar={isOpenSidebar} setSidebar={setSidebar}>
      {children}
    </LandingLayoutView>
  )
}

const LandingLayoutView: RFC<
  LandingLayoutProps & {
    isOpenSidebar: boolean
    setSidebar: Dispatch<SetStateAction<boolean>>
  }
> = ({ isOpenSidebar, setSidebar, children }) => {
  const isMobile = useMediaQuery(
    (theme: Theme) => theme.breakpoints.down('md'),
    { noSsr: true }
  )

  const handleOpenMenu = useCallback(() => setSidebar((prev) => !prev), [])

  return (
    <>
      <Header onClickMenu={handleOpenMenu} />

      {isMobile && <Sidebar open={isOpenSidebar} onChange={setSidebar} />}

      <Box component="main" display="flex" flexDirection="column">
        {children}
      </Box>

      <LazyHydrate whenVisible>
        <Footer />
      </LazyHydrate>
    </>
  )
}

export default LandingLayout
