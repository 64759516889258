import { useRouter } from 'next/router'
import React from 'react'

import { Button, ButtonProps } from '@mui/material'

import Text, { TextProps } from 'app/components/base/Text'

import { LANDING_PATH } from '../helpers/landing.constants'
import useSignUpModalContext from '../hooks/useSignUpModalContext'

interface SignUpButtonProps {
  textProps?: TextProps
  buttonProps?: ButtonProps
}

const SignUpButton: RFC<SignUpButtonProps> = ({ textProps, buttonProps }) => {
  const router = useRouter()
  const { showModal } = useSignUpModalContext()

  const isLanding = router?.pathname === LANDING_PATH

  const handleClick = () =>
    isLanding ? showModal() : router.push(LANDING_PATH)

  return (
    <Button
      variant="contained"
      size="small"
      color="secondary"
      {...buttonProps}
      onClick={handleClick}
    >
      <Text {...textProps}>Register Now</Text>
    </Button>
  )
}

export default SignUpButton
