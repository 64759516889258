import React, { createContext } from 'react'

import useModal from 'app/hooks/useModal'

export const SignUpModalContext = createContext<{
  open: boolean
  showModal: () => void
  hideModal: () => void
}>({ open: false, showModal: () => {}, hideModal: () => {} })

const SignUpModalProvider: RFC = ({ children }) => {
  const { open, showModal, hideModal } = useModal()

  return (
    <SignUpModalContext.Provider value={{ open, showModal, hideModal }}>
      {children}
    </SignUpModalContext.Provider>
  )
}

export default SignUpModalProvider
