import { useContext } from 'react'

import { SignUpModalContext } from '../providers/SignUpModalProvider'

const useSignUpModalContext = () => {
  const { open, showModal, hideModal } = useContext(SignUpModalContext)

  return { open, showModal, hideModal }
}

export default useSignUpModalContext
